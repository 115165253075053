var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"get-gas-token-to-use-input"},[_c('div',{staticClass:"verti bg-opacity-25 sm:px-6 sm:pt-7 rounded-3xl"},[_c('div',{staticClass:"flex justify-between mb-4 sm:mb-6"},[_c('p',{staticClass:"font-bold text-secondary text-xl text-center sm:text-left uppercase px-8 sm:px-0"},[_vm._v(" "+_vm._s(_vm.$t('components.getGasTokenInput.iAmSwapping'))+" ")]),(_vm.isDesktop)?_c('div',{staticClass:"flex"},[_c('p',{staticClass:"text-secondary text-xl text-left uppercase px-0"},[_vm._v(" "+_vm._s(_vm.$t('components.getGasTokenInput.available'))+" ")]),_c('p',{staticClass:"font-bold text-left text-xl px-0 ml-1",class:{
            'text-secondary': _vm.isValid === null,
            'text-coral-red': _vm.isValid === false,
            'text-primary': _vm.isValid === true,
          }},[_vm._v(" "+_vm._s(_vm.neoOwned)+" ")])]):_vm._e()]),_c('div',{staticClass:"horiz items-center border-solid border-2 bg-white rounded-full py-2 px-7 justify-between cursor-text",class:{
        'border-light-cyan-blue': _vm.isValid === null,
        'border-coral-red': _vm.isValid === false,
        'border-primary': _vm.isValid === true,
      },on:{"click":_vm.focusOnInput}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedNeoToUse),expression:"computedNeoToUse"}],staticClass:"get-gas-token-to-use-input__input",attrs:{"id":"neoInput","autocomplete":"off","inputmode":"numeric","name":"neo-input"},domProps:{"value":(_vm.computedNeoToUse)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.computedNeoToUse=$event.target.value},_vm.handleChangeNeoInputValue]}}),_c('div',{staticClass:"horiz items-center gap-x-2 flex-shrink-0",on:{"click":_vm.focusOnInput}},[_c('div',{staticClass:"mr-2 ml-4 h-6 sm:h-8 w-1 md:mx-3 rounded-full",class:{
            'bg-light-cyan-blue': _vm.isValid === null,
            'bg-coral-red': _vm.isValid === false,
            'bg-primary': _vm.isValid === true,
          }}),_vm._m(0),_c('span',{staticClass:"text-2xl sm:text-3xl font-medium text-slate-blue"},[_vm._v(" "+_vm._s(_vm.$t('components.getGasTokenInput.neo'))+" ")])])]),(!_vm.isDesktop)?_c('div',{staticClass:"flex px-6 mt-2 flex-row-reverse"},[_c('p',{staticClass:"font-bold text-xl text-left ml-1",class:{
          'text-secondary': _vm.isValid === null,
          'text-coral-red': _vm.isValid === false,
          'text-primary': _vm.isValid === true,
        }},[_vm._v(" "+_vm._s(_vm.neoOwned)+" ")]),_c('p',{staticClass:"text-secondary text-xl text-left uppercase"},[_vm._v(" "+_vm._s(_vm.$t('components.getGasTokenInput.available'))+" ")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-btn flex justify-center items-center rounded-full w-10 h-10 sm:w-14 sm:h-14"},[_c('img',{staticClass:"w-6 h-6 md:h-9 md:w-9",attrs:{"alt":"neo icon","src":require("@/assets/img/neo.svg")}})])}]

export { render, staticRenderFns }