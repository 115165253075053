




































import {Component, Mixins, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {WalletPlatform} from '@/enums/WalletPlatform'
import ChooseWallet from '@/components/walletConnection/ChooseWallet.vue'
import {ConnectWalletStep} from '@/enums/ConnectWalletStep'
import NeonConnect from '@/components/walletConnection/NeonConnect.vue'
import {UnimplementedWallet} from '@/model/wallets/types/WalletErrors'

@Component({
  computed: {
    ConnectWalletStep() {
      return ConnectWalletStep
    },
  },
  components: {NeonConnect, ChooseWallet},
})
export default class ConnectWalletModal extends Mixins(MixinScreenSize) {
  actionToDoAfterConnect: (() => Promise<void> | void) | null = null
  modalState = ConnectWalletStep.CHOOSE_WALLET
  isWaitingConnection = false

  resetModal() {
    this.isWaitingConnection = false
    this.modalState = ConnectWalletStep.CHOOSE_WALLET
  }

  async handleConnectNeon() {
    this.modalState = ConnectWalletStep.NEON_CONNECT
    await this.$walletAdapter.connect(WalletPlatform.NEON)
  }

  async handleConnectNeoline() {
    if (await this.$walletAdapter.canUseWallet(WalletPlatform.NEOLINE)) {
      await this.$walletAdapter.connect(WalletPlatform.NEOLINE)
    } else {
      window.open(
        'https://chromewebstore.google.com/detail/neoline/cphhlgmgameodnhkjdmkpanlelnlohao?pli=1'
      )
    }
  }

  async handleWalletConnection(wallet: WalletPlatform): Promise<void> {
    try {
      this.isWaitingConnection = true

      switch (wallet) {
        case WalletPlatform.NEON:
          await this.handleConnectNeon()
          break
        case WalletPlatform.NEOLINE:
          await this.handleConnectNeoline()
          break
        default:
          throw UnimplementedWallet
      }

      this.$modal.close('connectWalletModal')
    } catch {
      this.resetModal()
    }
  }

  async openEvent(actionToDoAfterConnect?: () => Promise<void> | void) {
    if (typeof actionToDoAfterConnect !== 'undefined') {
      this.actionToDoAfterConnect = actionToDoAfterConnect
    }
    this.$emit('open')
  }

  async closeEvent() {
    this.resetModal()
    this.$emit('close')

    if (
      this.actionToDoAfterConnect &&
      this.$walletAdapter.connectedWalletPlatform
    )
      await this.actionToDoAfterConnect()
  }

  @Watch('$store.state.walletAdapter.address')
  onAlreadyConnected(address: string | null) {
    if (address) this.closeEvent()
  }
}
