






import {Vue, Prop, Component} from 'vue-property-decorator'

@Component
export default class DropdownItem extends Vue {
  @Prop({type: String, required: true}) to!: string
  @Prop({type: String, required: true}) text!: string
}
