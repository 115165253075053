




































































































































import FilterToggle from '@/components/FilterToggle.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import {Component, Prop, Vue} from 'vue-property-decorator'

export type CookieConsentBannerCookie = {
  name: string
  duration: string
  description: string
}
@Component({
  components: {GenericToggleButton, FilterToggle},
})
export default class CookieConsentBannerAccordion extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: String, required: true}) description!: string
  @Prop({type: Array, required: true}) cookies!: CookieConsentBannerCookie[]
  @Prop({type: Boolean, default: true}) withToggle!: boolean
  @Prop({type: Boolean, default: false}) isActive!: boolean

  open = false
  toogleActive = this.isActive

  handleOpen() {
    this.open = !this.open
  }

  handleToggle(value: boolean) {
    this.$emit('toggle', value)
  }
}
