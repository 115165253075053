var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"get-gas-modal",class:{
    'get-gas-modal-mobile': _vm.isMobile && _vm.currentStep === _vm.GetGasStep.FORM,
    'get-gas-modal-progress': _vm.isProgressStep,
  }},[_c('modal',{attrs:{"closable":_vm.canBeClosed,"closeOutside":_vm.canBeClosed,"name":"getGasModal"},on:{"close":_vm.closeEvent,"open":_vm.openEvent}},[_c('await',{attrs:{"name":"populateAccountBalance"}},[(_vm.currentStep === _vm.GetGasStep.FORM)?_c('transition',{staticClass:"mb-5",attrs:{"mode":"out-in","name":"fade-left"}},[_c('get-gas-form-step',{attrs:{"amount-to-receive":_vm.toFixedOrEmpty(_vm.amountToReceive),"amount-to-use":_vm.toFixedOrEmpty(_vm.amountToUse),"neo-owned":_vm.toFixedOrEmpty(_vm.neoOwned)},on:{"proceedClick":_vm.handleFormProceedClick,"update:amountToUse":_vm.setAmountToUse}})],1):(_vm.currentStep === _vm.GetGasStep.WAITING_SIGNATURE)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('swap-loading-transaction-step',{staticClass:"text-raspberry-pink",attrs:{"description":_vm.$translate(
              'components.getGasModal.waitingSignatureStep.description'
            ),"title":_vm.$translate('components.getGasModal.waitingSignatureStep.title')}})],1):(_vm.currentStep === _vm.GetGasStep.VALIDATING_TRANSACTION)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('swap-loading-transaction-step',{staticClass:"text-primary",attrs:{"description":_vm.$translate(
              'components.getGasModal.validatingTransactionStep.description'
            ),"title":_vm.$translate(
              'components.getGasModal.validatingTransactionStep.title'
            )}})],1):(_vm.transactionHash && _vm.currentStep === _vm.GetGasStep.SWAPPED)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('swap-swapped-step',{attrs:{"transaction-hash":_vm.transactionHash}})],1):(_vm.currentStep === _vm.GetGasStep.UNKNOWN_ERROR)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('swap-unexpected-error-step')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }