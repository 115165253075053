














































































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {EnvHelper} from '@/helpers/EnvHelper'

@Component
export default class GetGasTokenToUseInput extends MixinScreenSize {
  @Prop({type: String, required: true}) neoToUse!: string
  @Prop({type: String, required: true}) neoOwned!: string

  computedNeoToUse: string = this.neoToUse
  alreadyImputed = false
  isValid: boolean | null = null

  focusOnInput() {
    const input = this.$el.querySelector(
      '.get-gas-token-to-use-input__input'
    ) as HTMLInputElement

    input.focus()
  }

  handleChangeNeoInputValue(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(
      /[^0-9]/g,
      ''
    )

    const parsedValue = parseInt(value)

    let newValue = value

    if (
      value.length - EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY.length > 1 &&
      parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)
    ) {
      newValue = EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY
    } else if (parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)) {
      newValue = value.slice(0, -1)
    }

    this.computedNeoToUse = newValue

    this.alreadyImputed = true
    this.$emit('input', newValue)

    this.isValid = Number(this.computedNeoToUse) <= Number(this.neoOwned)
  }
}
