
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {WalletPlatform} from '@/enums/WalletPlatform'

@Component({
  computed: {
    WalletPlatform() {
      return WalletPlatform
    },
  },
})
export default class ChooseWallet extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, required: true}) isWaitingConnection!: boolean
  @Prop({type: Function, required: true}) handleWalletConnection!: (
    wallet: WalletPlatform
  ) => Promise<void>
  canUseNeoline = false
  canUseNeon = false

  async created() {
    await this.onSDKLoaded()
  }

  @Watch('$store.state.walletAdapter.walletsImplementationStatus')
  async onSDKLoaded() {
    const [canUseNeoline, canUseNeon] = await Promise.all([
      this.$walletAdapter.canUseWallet(WalletPlatform.NEOLINE),
      this.$walletAdapter.canUseWallet(WalletPlatform.NEON),
    ])
    this.canUseNeoline = canUseNeoline
    this.canUseNeon = canUseNeon
  }
}
