

































































































































































































































































import {Vue, Component} from 'vue-property-decorator'
import CookieConsentBannerButton from '@/components/cookieConsentBanner/CookieConsentBannerButton.vue'
import {bootstrap} from 'vue-gtag'
import CookieConsentBannerAccordion, {
  CookieConsentBannerCookie,
} from '@/components/cookieConsentBanner/CookieConsentBannerAccordion.vue'
import {Portal} from 'portal-vue'

const cookiesConsentGrantedKey = 'cookies-consent-granted'

@Component({
  components: {Portal, CookieConsentBannerAccordion, CookieConsentBannerButton},
})
export default class CookieConsentBanner extends Vue {
  necessaryCookies: CookieConsentBannerCookie[] = [
    {
      name: 'JSESSIONID',
      duration: this.$translate(
        'components.cookieConsentBanner.cookieDurations.jSessionId'
      ),
      description: this.$translate(
        'components.cookieConsentBanner.cookieDescriptions.jSessionId'
      ),
    },
  ]
  performanceCookies: CookieConsentBannerCookie[] = [
    {
      name: 'rollbar-debugging-tool',
      duration: this.$translate(
        'components.cookieConsentBanner.cookieDurations.rollbarDebuggingTool'
      ),
      description: this.$translate(
        'components.cookieConsentBanner.cookieDescriptions.rollbarDebuggingTool'
      ),
    },
  ]
  analyticsCookies: CookieConsentBannerCookie[] = [
    {
      name: '_ga',
      duration: this.$translate(
        'components.cookieConsentBanner.cookieDurations.ga'
      ),
      description: this.$translate(
        'components.cookieConsentBanner.cookieDescriptions.ga'
      ),
    },
  ]
  allCookies = [
    ...this.necessaryCookies,
    ...this.performanceCookies,
    ...this.analyticsCookies,
  ]

  previousAllowedCookies: CookieConsentBannerCookie[] = []
  toggleSelectCookies: CookieConsentBannerCookie[] = [...this.necessaryCookies]

  showBanner = false
  showModal = false
  isManagingCookies = false
  shouldReload = false

  created() {
    const cookiesConsentGranted = localStorage.getItem(cookiesConsentGrantedKey)
    if (!cookiesConsentGranted) {
      this.showBanner = true
      return
    }

    this.previousAllowedCookies = JSON.parse(cookiesConsentGranted)
    this.toggleSelectCookies = this.previousAllowedCookies

    this.handleChangeCookies(this.previousAllowedCookies)
  }

  isAccordionActive(cookies: CookieConsentBannerCookie[]) {
    return cookies.every(cookie =>
      this.previousAllowedCookies.some(c => c.name === cookie.name)
    )
  }

  handleManageCookies() {
    this.showModal = true
    this.isManagingCookies = true
  }

  handleToggle(active: boolean, cookies: CookieConsentBannerCookie[]) {
    if (active) {
      this.toggleSelectCookies.push(...cookies)
    } else {
      this.toggleSelectCookies = this.toggleSelectCookies.filter(
        cookie => !cookies.some(c => c.name === cookie.name)
      )
    }
  }

  handleAcceptAll() {
    this.handleChangeCookies(this.allCookies)
  }

  handleRejectAll() {
    this.handleChangeCookies([])
  }

  handleSavePreferences() {
    this.handleChangeCookies(this.toggleSelectCookies)
  }

  handleCustomize() {
    this.showModal = true
  }

  handleRollbarDebuggingTool(accepted: boolean) {
    this.$rollbar.configure({captureIp: accepted})
  }

  handleJSessionId(accepted: boolean) {
    if (!accepted) {
      this.$cookies.remove('JSESSIONID')
    }
  }

  async handleGa(accepted: boolean) {
    if (!accepted) {
      const keys = this.$cookies.keys()

      keys.forEach(key => {
        if (key.startsWith('_ga')) {
          this.$cookies.remove(key)
        }
      })

      if (this.isManagingCookies) {
        this.shouldReload = true
      }
      return
    }

    await bootstrap()
  }

  handleChangeCookies(acceptedCookies: CookieConsentBannerCookie[]) {
    this.showBanner = false
    this.showModal = false

    const functionsByCookiesName: Record<
      string,
      (accepted: boolean) => void | Promise<void>
    > = {
      'rollbar-debugging-tool': this.handleRollbarDebuggingTool,
      JSESSIONID: this.handleJSessionId,
      _ga: this.handleGa,
    }

    for (const cookie of this.allCookies) {
      const accepted = acceptedCookies.some(
        acceptedCookie => acceptedCookie.name === cookie.name
      )

      const previousAccepted = this.previousAllowedCookies.some(
        previousAllowedCookie => previousAllowedCookie.name === cookie.name
      )

      if (accepted === previousAccepted) {
        continue
      }

      functionsByCookiesName[cookie.name](accepted)
    }

    localStorage.setItem(
      cookiesConsentGrantedKey,
      JSON.stringify(acceptedCookies)
    )
    this.previousAllowedCookies = acceptedCookies

    if (this.shouldReload) {
      window.location.reload()
    }
  }
}
