import { render, staticRenderFns } from "./SwapLoadingTransactionStep.vue?vue&type=template&id=e6f5ab6a&scoped=true"
import script from "./SwapLoadingTransactionStep.vue?vue&type=script&lang=ts"
export * from "./SwapLoadingTransactionStep.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6f5ab6a",
  null
  
)

export default component.exports