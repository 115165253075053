


























































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {QrcodeVue},
})
export default class NeonConnect extends Vue {
  base64URI: string | null = null
  uri: string = ''

  copy() {
    this.$utils.copyToClipboard(this.$walletAdapter.uri ?? '')
    this.$toast.info('system.info.copiedToClipboard')
  }

  @Watch('$store.state.walletAdapter.uri')
  onUriChange(uri: string | null) {
    if (!uri) {
      this.base64URI = null
      this.uri = ''
      return
    }
    this.uri = uri
    this.base64URI = Buffer.from(uri).toString('base64')
  }
}
