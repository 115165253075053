











































import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({})
export default class CookieConsentBannerButton extends Vue {
  @Prop({type: String, required: true}) label!: string
  @Prop({type: Boolean, default: true}) outline!: boolean
}
