
























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({components: {}})
export default class GetGasTokenToReceiveInput extends Vue {
  @Prop({type: String, required: true}) amount!: string

  focusOnInput() {
    const input = this.$el.querySelector(
      '.swap-input-select__input'
    ) as HTMLInputElement
    input.focus()
  }
}
