













import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Fragment} from 'vue-fragment'

@Component({
  components: {Fragment},
})
export default class FooterLink extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: true}) label!: string
  @Prop({type: String, required: true}) route!: string
  @Prop({type: Boolean, required: false, default: true})
  withSeparator!: boolean

  formatText(text: String): String {
    return text.replace('\n', '<br><br>')
  }
}
