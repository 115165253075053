


























































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class SwapSwappedStep extends Vue {
  @Prop({type: String, required: true}) transactionHash!: string

  get doraLink() {
    return `https://dora.coz.io/transaction/neo3/mainnet/${this.transactionHash}`
  }
}
