var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cookie-consent-banner"},[_c('li',[_c('button',{staticClass:"outline-none text-center md:text-left",on:{"click":_vm.handleManageCookies}},[_vm._v(" "+_vm._s(_vm.$translate('components.cookieConsentBanner.manageCookies'))+" ")])]),_c('portal',{attrs:{"to":"consent-banner"}},[_c('div',{staticClass:"cookie-consent-banner__banner"},[(_vm.showBanner && !_vm.showModal)?_c('div',{staticClass:"cookie-consent-banner__banner--content"},[_c('div',{staticClass:"w-full bg-white border-cutty-gray border-2 py-6 px-8 rounded-lg flex gap-6 flex-col md:flex-row text-left"},[_c('div',{staticClass:"flex flex-col gap-y-3"},[_c('h3',{staticClass:"font-bold text-2xl sm:text-3xl text-bunker"},[_vm._v(" "+_vm._s(_vm.$translate('components.cookieConsentBanner.title'))+" ")]),_c('p',{staticClass:"text-lg sm:text-xl text-regent-gray"},[_vm._v(" "+_vm._s(_vm.$translate('components.cookieConsentBanner.description'))+" "),_c('a',{staticClass:"whitespace-no-wrap underline text-primary",attrs:{"href":"/privacy-policy"}},[_vm._v(_vm._s(_vm.$translate('components.cookieConsentBanner.privacyPolicy'))+" ")])])]),_c('div',{staticClass:"flex gap-2 items-center flex-col"},[_c('div',{staticClass:"flex gap-2 items-center w-full"},[_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate(
                    'components.cookieConsentBanner.buttonCustomizeLabel'
                  )},on:{"click":_vm.handleCustomize}}),_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate(
                    'components.cookieConsentBanner.buttonRejectAllLabel'
                  )},on:{"click":_vm.handleRejectAll}})],1),_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate(
                  'components.cookieConsentBanner.buttonAcceptAllLabel'
                ),"outline":false},on:{"click":_vm.handleAcceptAll}})],1)])]):_vm._e()]),_c('modal',{attrs:{"closable":true,"closeOutside":true,"innerClass":"w-auto"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('h4',{staticClass:"font-semibold text-3xl text-bunker px-8 sm:px-16 mb-4 sm:mb-9"},[_vm._v(" "+_vm._s(_vm.$translate('components.cookieConsentBanner.customizeModal.title'))+" ")]),_c('div',{staticClass:"cookie-consent-banner__modal-content"},[_c('p',{staticClass:"font-medium text-lg sm:text-2xl text-regent-gray "},[_vm._v(" "+_vm._s(_vm.$translate( 'components.cookieConsentBanner.customizeModal.description' ))+" ")]),_c('div',{staticClass:"my-8 sm:my-12 flex flex-col gap-y-6 sm:gap-y-10"},[_c('cookie-consent-banner-accordion',{attrs:{"title":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.necessaryAccordionTitle'
              ),"description":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.necessaryAccordionDescription'
              ),"cookies":_vm.necessaryCookies,"with-toggle":false,"is-active":_vm.isAccordionActive(_vm.necessaryCookies)}}),_c('cookie-consent-banner-accordion',{attrs:{"title":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.analyticsAccordionTitle'
              ),"description":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.analyticsAccordionDescription'
              ),"cookies":_vm.analyticsCookies,"is-active":_vm.isAccordionActive(_vm.analyticsCookies)},on:{"toggle":function (value) { return _vm.handleToggle(value, _vm.analyticsCookies); }}}),_c('cookie-consent-banner-accordion',{attrs:{"title":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.performanceAccordionTitle'
              ),"description":_vm.$translate(
                'components.cookieConsentBanner.customizeModal.performanceAccordionDescription'
              ),"cookies":_vm.performanceCookies,"is-active":_vm.isAccordionActive(_vm.performanceCookies)},on:{"toggle":function (value) { return _vm.handleToggle(value, _vm.performanceCookies); }}})],1)]),_c('div',{staticClass:"flex gap-3 sm:px-16 sm:pb-6 px-8 pb-4 flex-col sm:flex-row"},[_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate('components.cookieConsentBanner.buttonRejectAllLabel')},on:{"click":_vm.handleRejectAll}}),_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate(
              'components.cookieConsentBanner.buttonSavePreferencesLabel'
            )},on:{"click":_vm.handleSavePreferences}}),_c('cookie-consent-banner-button',{attrs:{"label":_vm.$translate('components.cookieConsentBanner.buttonAcceptAllLabel'),"outline":false},on:{"click":_vm.handleAcceptAll}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }