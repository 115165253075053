


































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import FooterLink from './FooterLink.vue'

@Component({
  components: {FooterLink},
})
export default class AccordionFooter extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: true}) title!: String

  isAccordionVisible = true
  isAccordionOpened = false

  handleClickAccordion() {
    if (this.isDesktop || this.isSmallDesktop) {
      return
    }
    this.isAccordionOpened = !this.isAccordionOpened
  }
}
