import {wallet} from '@cityofzion/neon-core'
import BigNumber from 'bignumber.js'
import {NeonInvoker} from '@cityofzion/neon-invoker'
import {EnvHelper} from '@/helpers/EnvHelper'

type TGetAmountsOutFlamingoArgs = {
  amountIn: string
  decimalsTokenToUse?: number
  hashTokenToUse: string
  hashTokenToReceive: string
}

export class GleederHelper {
  static neonInvoker: NeonInvoker | null = null
  static readonly rpcUrl = EnvHelper.VUE_APP_RPC_URL
  static readonly flamingoSwapRouterScriptHash =
    EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH
  static readonly gasScriptHash = EnvHelper.VUE_APP_GAS_SCRIPT_HASH

  static async getNeonInvoker(): Promise<NeonInvoker> {
    if (!this.neonInvoker) {
      this.neonInvoker = await NeonInvoker.init({
        rpcAddress: this.rpcUrl,
        account: new wallet.Account(),
      })
    }

    return this.neonInvoker
  }

  static async getAmountsOutFlamingo({
    amountIn,
    decimalsTokenToUse = 0,
    hashTokenToUse,
    hashTokenToReceive,
  }: TGetAmountsOutFlamingoArgs) {
    const fixedAmount = BigNumber(amountIn)
      .shiftedBy(decimalsTokenToUse)
      .toString()
    const neonInvoker = await this.getNeonInvoker()
    return neonInvoker.testInvoke({
      invocations: [
        {
          operation: 'getAmountsOut',
          scriptHash: this.flamingoSwapRouterScriptHash,
          args: [
            {type: 'Integer', value: fixedAmount},
            {
              type: 'Array',
              value: [
                {
                  type: 'Hash160',
                  value: hashTokenToUse,
                },
                {
                  type: 'Hash160',
                  value: hashTokenToReceive,
                },
              ],
            },
          ],
        },
      ],
    })
  }
}
