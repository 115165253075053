





























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {
  @Prop({type: String, required: true}) text!: string

  isOpen = false

  toggle() {
    this.isOpen = !this.isOpen
  }

  close() {
    this.isOpen = false
  }

  created() {
    this.$root.$on('dropdown::open', this.rootCloseListener)
  }

  mounted() {
    document.addEventListener('click', this.clickOutListener)
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutListener)
  }

  clickOutListener(evt: any) {
    if (!this.$el.contains(evt.target)) {
      this.close()
    }
  }

  rootCloseListener(vm: any) {
    if (vm !== this) {
      this.close()
    }
  }
}
