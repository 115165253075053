










































































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import moment from 'moment'
import FooterLink from '../components/footer/FooterLink.vue'
import CookieConsentBanner from '@/components/cookieConsentBanner/CookieConsentBanner.vue'
import AccordionFooter from './footer/AccordionFooter.vue'

@Component({
  components: {CookieConsentBanner, FooterLink, AccordionFooter},
})
export default class AppFooter extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: false}) addToList!: string
  get year() {
    return moment().year()
  }

  get addToListString() {
    return (
      this.$t('components.footer.contact.addYour') +
      this.addToList +
      this.$t('components.footer.contact.toTheList')
    )
  }
}
