



































































import {Component, Vue} from 'vue-property-decorator'

@Component
export default class SwapInsufficientGasStep extends Vue {
  get neoLink() {
    return this.$translate('components.swapInsufficientGasStep.neoLink')
  }
}
