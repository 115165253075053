






















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class SwapLoadingTransactionStep extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: String, required: true}) description!: string
}
